<template>
  <div>
    <!-- Row Start -->
    <div class="row">
      <div class="col-12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">State</h3>
            </div>
            <div class="card-toolbar">
              <b-button variant="outline-primary" v-b-modal.add-state-modal
                >Add State</b-button
              >
            </div>
          </div>

          <div class="card-body body-fluid">
            <b-table striped hover :items="states" :fields="fields">
              <template #cell(actions)="row">
                <b-button @click="ShowEditModal(row.item.id)" variant="primary"
                  >Edit</b-button
                >
                <b-button
                  @click="deletestate(row.item.id)"
                  class="ml-2"
                  variant="danger"
                  >Delete</b-button
                >
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <!-- Row End -->
    <!-- Add Modal -->
    <b-modal
      id="add-state-modal"
      ref="modal"
      @show="resetModal"
      @hidden="resetModal"
      @ok="addstate"
      title="Add State"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-12">
            <b-form-group
              label="Country"
              invalid-feedback="Country is required"
              :state="countryState"
            >
              <b-form-select
                v-model="form.country_id"
                :state="countryState"
                :options="countries"
                value-field="id"
                text-field="name"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              label="State Name"
              label-for="name-input"
              invalid-feedback="Name is required"
              :state="nameState"
            >
              <b-form-input
                id="name-input"
                v-model="form.name"
                :state="nameState"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Branch">
              <b-form-select
                v-model="form.branch_id"
                :options="branches"
                value-field="id"
                text-field="name"
              >
                <template #first>
                  <b-form-select-option :value="null"
                    >Select</b-form-select-option
                  >
                </template></b-form-select
              >
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>

    <!-- Edit Modal -->
    <b-modal
      id="edit-state-modal"
      ref="modal"
      @hidden="resetModal"
      @ok="updatestate"
      title="Edit State"
    >
      <form ref="editform" @submit.stop.prevent="handleSubmit">
        <div class="row">
          <div class="col-md-12">
            <b-form-group
              label="Country"
              invalid-feedback="Country is required"
            >
              <b-form-select
                v-model="form.country_id"
                :options="countries"
                value-field="id"
                text-field="name"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              label="State Name"
              label-for="name-input-1"
              invalid-feedback="Name is required"
            >
              <b-form-input
                id="name-input-1"
                v-model="form.name"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Branch">
              <b-form-select
                v-model="form.branch_id"
                :options="branches"
                value-field="id"
                text-field="name"
              >
                >
                <template #first>
                  <b-form-select-option :value="null"
                    >Select</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </div>
        </div>
      </form>
    </b-modal>
    <!-- </form> -->
    <loading :active.sync="isLoading"></loading>
  </div>
</template>
<script>
// import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  data() {
    return {
      states: [],
      errors: [],
      isLoading: false,
      fields: ["name", "country", "branch", "actions"],
      form: {
        name: null,
        country_id: null,
        branch_id: null,
        id: null,
      },
      countries: [],
      branches: [],
      nameState: null,
      countryState: null,
      submittedNames: [],
    };
  },
  components: {
    Loading,
  },
  created() {
    this.getAllStates();
    this.getAllCountries();
    this.getAllBranches();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Settings" },
      { title: "State" },
    ]);
  },
  methods: {
    checkErrors() {
      if (this.form.name && this.form.country_id) {
        return true;
      }

      this.errors = [];

      if (!this.form.name) {
        this.errors.push("Name is required.");
      }
      if (!this.form.country_id) {
        this.errors.push("Country is required.");
      }
      //  if (!this.form.branch_id) {
      //   this.errors.push("Branch is required.");
      // }
      for (let field of this.errors) {
        this.makeToastVariant("danger", field);
      }
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      this.countryState = valid;
      return valid;
    },
    checkEditFormValidity() {
      const valid = this.$refs.editform.checkValidity();
      this.nameState = valid;
      this.countryState = valid;
      return valid;
    },
    resetModal() {
      this.form.name = null;
      this.form.country_id = null;
      this.form.branch_id = null;
      this.nameState = null;
      this.countryState = null;
    },
    addstate(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Exit when the form isn't valid
      // if (!this.checkFormValidity()) {
      //   return;
      // }
      if (this.checkErrors() == true) {
        this.isLoading = true;
        ApiService.post("State", this.form)
          .then(() => {
            
            this.getAllStates();
            this.$nextTick(() => {
              this.$bvModal.hide("add-state-modal");
            });
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant("danger", response.data.error[field][0]);
            }
          });
      }
    },
    makeToastVariant(variant = null, text) {
      this.$bvToast.toast(text, {
        title: `Error`,
        variant: variant,
        solid: true,
      });
    },
    updatestate(bvModalEvt) {
      bvModalEvt.preventDefault();
      
      // // Exit when the form isn't valid
      // if (!this.checkEditFormValidity()) {
      //   return;
      // }
      if (this.checkErrors() == true) {
        this.isLoading = true;
        ApiService.update("State", this.form.id, this.form)
          .then(() => {
            this.getAllStates();
            this.$nextTick(() => {
              this.$bvModal.hide("edit-state-modal");
            });
          })
          .catch(() => {
            this.isLoading = false;
            // context.commit(SET_ERROR, response.data.errors);
            // context.commit(SET_ERROR, response);
          });
      }
    },
    getAllStates() {
      this.isLoading = true;
      ApiService.get("getstates")
        .then(({ data }) => {
          
          this.states = data;
          this.isLoading = false;
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    getAllCountries() {
      ApiService.get("getcountries")
        .then(({ data }) => {
          this.countries = data;
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    ShowEditModal(id) {
      this.isLoading = true;
      ApiService.get("State", id)
        .then(({ data }) => {
          this.form = data;
          this.isLoading = false;
          this.$bvModal.show("edit-state-modal");
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    deletestate(id) {
      this.isLoading = true;
      ApiService.delete("State", id)
        .then(() => {
          this.getAllStates();
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    getAllBranches() {
      ApiService.get("branch")
        .then(({ data }) => {
          
          this.branches = data;
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
  },
};
</script>
